import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from "Global";
import AutocompleteWarehouses from "elements/AutocompleteWarehouses";

export default function MovementEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, movement_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    movement_id: movement_id ? movement_id : "null",
    warehouse_id: "",
    user_id: "",
    document_number: "",
    comments: "",
    movement_type: "outgoing",
    products: []
  };


  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (movement_id) {
      axios
        .get(`${ProjectConfig.api_url}/inventorymovements/details`, {
          params: { movement_id },
        })
        .then((response) => {
          console.log("RESPONSE", response.data);
          setFormValues(response.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      setFormValues(defaultValues);
    }
  }, [movement_id]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  /*===============================================================
  SUBMIT FORM
  ===============================================================*/
  const handleSubmit = () => {
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);

    axios
      .post(ProjectConfig.api_url + "/inventorymovements/addedit", body, {
        headers: {
          "Content-Type": "multipart/form-data",
          "ltkn": localStorage.getItem("ltkn"),
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.status === "success") {
          enqueueSnackbar("Movimiento guardado con éxito", { variant: "success" });
          setOpenEditor(false);
          loadList();
        } else {
          console.log("aaaa ",response.data);
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)}>
      <div className="DialogContainer" style={{ maxWidth: "502px" }}>
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Movimiento de Inventario</h2>
              <span className="h2_subtitle">Editar</span>
            </div>
          </div>

          <div className="input_group">

            <AutocompleteWarehouses
              value={formValues.warehouse_id}
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  warehouse_id: e.target.value,
                });
              }}
            />
    


            <TextField
              id="user_id"
              name="user_id"
              label="ID del Usuario"
              type="text"
              required
              fullWidth
              value={formValues.user_id}
              onChange={handleInputChange}
            />

            <TextField
              id="comments"
              name="comments"
              label="Comentarios"
              type="text"
              required
              fullWidth
              value={formValues.comments}
              onChange={handleInputChange}
            />
          </div>

          <div className="dialogButtons">
            <Button onClick={() => setOpenEditor(false)} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
