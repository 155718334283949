import React, { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    TextField,
    Link,
} from '@mui/material';

import Box from 'elements/Box';
import { ProjectConfig } from 'Global';

export default function FormChangePass( props: any ) {
    
    const {
        loadingButton,
        logo,
        setLoading,
        setState,
        email,
        code,
    } = props;

    /*================================================
    SNACKBAR FUNCTION FOR NOTIFICATIONS
    ================================================*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    const [newPassword,setNewPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');


    /*================================================
    CHANGE PASSWORD (SUBMIT)
    ================================================*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if(newPassword !== confirmPassword){
            enqueueSnackbar("Passwords do not match", { variant: 'error' });
            return;
        }
        setLoading(true);
        axios.get(ProjectConfig.api_url, {
            params: {
                request: "change_password",
                email: email,
                code: code,
                newPassword: newPassword,
                confirmPassword: confirmPassword,
            },
        })
        .then((response) => {
            if(response.data.status === "success"){
                enqueueSnackbar(response.data.message, { variant: 'success' });
                setState('login');
            } else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
            }
        })
        .catch((error) => {
            enqueueSnackbar(error.toString(), { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    };
    /*================================================
    ================================================*/

    return(
        <Box id='loginBox'>
          <div className='loginFieldsContainer'>
            <h1 className='h1_login'>Nueva contraseña</h1>
            
            <form className='form'>
              <TextField
                required
                fullWidth
                name="newPassword"
                label="Nueva contraseña"
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                required
                fullWidth
                name="confirmPassword"
                label="Confirma nueva contraseña"
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={newPassword !== confirmPassword && confirmPassword !== '' ? true : false}
              />
            </form>
          </div>

          <div className='loginButtonContainer'>
            <LoadingButton
              size="small"
              type="submit"
              loading={loadingButton}
              fullWidth
              variant="contained"
              sx={{ fontSize: '13px', padding: '10px 0px'}}
            >
              Cambiar contraseña
            </LoadingButton>
          </div>
        </Box>
    );
}