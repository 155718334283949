import SpeedIcon from '@mui/icons-material/Speed';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PersonIcon from '@mui/icons-material/Person';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StarIcon from '@mui/icons-material/Star';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import {
    AdminPanelSettings,
    Business,
    Category,
    Inventory2,
    RocketLaunch,
    SupervisorAccount
  } from '@mui/icons-material';

export const Permissions = [
    {
        role_id: 1,
        routes: [
            {
                category: 'Administrador',
                components: [
                    {
                        path: 'dashboard', 
                        component: 'Dashboard',
                        icon: <SpeedIcon />,
                        label: 'Dashboard',
                    },
                ]
            },
            {
                category: 'General',
                components: [
                    {
                        path: '/administrators', 
                        component: 'AdminList',
                        icon: <AdminPanelSettings />,
                        label: 'Administradores',
                    },
                    {
                        path: '/companies', 
                        component: 'CompanyList',
                        icon: <SupervisorAccount />,
                        label: 'Empresas',
                    },
                    {
                        path: '/plans', 
                        component: 'PlanList',
                        icon: <WidgetsIcon />,
                        label: 'Planes',
                    },
                ]
            }
        ]
    },
    {   
        role_id: 2, 
        routes: [
            {
                category: 'Usuario',
                components: [
                    {
                        path: 'dashboard', 
                        component: 'Dashboard',
                        icon: <SpeedIcon />,
                        label: 'Dashboard',
                    },
                ]
            },
            {
                category: 'General',
                components: [
                    // {
                    //     path: '/clients', 
                    //     component: 'Clients',
                    //     icon: <SupervisorAccount />,
                    //     label: 'Clientes',
                    // },
                    {
                        path: '/users', 
                        component: 'Users',
                        icon: <PersonIcon />,
                        label: 'Usuarios',
                    },
                    {
                        path: '/sync', 
                        component: 'Sync',
                        icon: <CloudSyncIcon />,
                        label: 'Sincronizar',
                    },
                    
                ]
            },
            {
                category: 'Inventario',
                components: [
                    {
                        path: '/warehouses', 
                        component: 'Warehouses',
                        icon: <WarehouseIcon />,
                        label: 'Bodegas',
                    },
                    {
                        path: '/inventory', 
                        component: 'Products',
                        icon: <ChecklistIcon />,
                        label: 'Productos',
                    },
                    {
                        path: '/categories', 
                        component: 'Categories',
                        icon: <WidgetsIcon />,
                        label: 'Categorías',
                    },
                    {
                        path: '/suppliers', 
                        component: 'Suppliers',
                        icon: <LocalShippingIcon />,
                        label: 'Proveedores',
                    },
                    {
                        path: '/brands', 
                        component: 'Brands',
                        icon: <StarIcon />,
                        label: 'Marcas',
                    },
                    {
                        path: '/reports', 
                        component: 'Reports',
                        icon: <AssessmentOutlinedIcon />,
                        label: 'Reportes',
                    },
                    {
                        path: '/movements', 
                        component: 'Movements',
                        icon: <AssessmentOutlinedIcon />,
                        label: 'Movimientos',
                    },
                ]
            },
            {
                category: 'Pedidos',
                components: [
                    {
                        path: '/clients',
                        component: 'Clients',
                        icon: <ReceiptIcon />,
                        label: 'Clientes',
                    },
                    {
                        path: '/movements_concepts',
                        component: 'MovementsConcepts',
                        icon: <ReceiptIcon />,
                        label: 'Conceptos Movimientos',
                    },
                    {
                        path: '/undelivered_concepts',
                        component: 'UndeliveredConcepts',
                        icon: <ReceiptIcon />,
                        label: 'Conceptos No Efectivos',
                    },
                    {
                        path: '/assets_concepts',
                        component: 'AssetsConcepts',
                        icon: <RocketLaunch />,
                        label: 'Conceptos de Activos',
                    },
                ]
            },
            {
                category: 'Rutas',
                components: [
                    {
                        path: '/guides',
                        component: 'Guides',
                        icon: <MapIcon />,
                        label: 'Rutas',
                    },
                ]
            },
            
        ]
    },
]