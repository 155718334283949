import { 
    Button, 
    Dialog, 
    TextField, 
    FormControl, 
    Select, 
    InputLabel, 
    MenuItem
  } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from "Global";

export default function ConceptEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, concept_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    concept_id: concept_id ? concept_id : "null",
    concept: "",
    movement_type: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (concept_id) {
      axios
        .get(`${ProjectConfig.api_url}/inventorymovements/details_concept`, {
          params: { concept_id },
        })
        .then((response) => {
          setFormValues(response.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      setFormValues(defaultValues);
    }
  }, [concept_id]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

   const handleSubmit = () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);
    
    axios.post(ProjectConfig.api_url+"/inventorymovements/addedit_concept", body,
      {
        headers: {
          "ltkn": localStorage.getItem("ltkn"),
        },
      })
      .then((response) => {
        console.log(response.data);

        if(response.data.status === "success"){
          enqueueSnackbar('Guardado con éxito', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.response.data);
      }).
      finally(() => {
        setLoading(false);
      });  
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)}>
      <div className="DialogContainer" style={{ maxWidth: "502px" }}>
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Concepto</h2>
              <span className="h2_subtitle">Editar</span>
            </div>
          </div>

          <div className="input_group">
            <TextField
              id="concept"
              name="concept"
              label="Concepto"
              type="text"
              required
              fullWidth
              value={formValues.concept}
              onChange={handleInputChange}
            />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tipo de importación</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formValues.movement_type}
                label="Tipo de importación"
                name="movement_type"
                onChange={handleInputChange}
              >
                <MenuItem value={"incoming"}>Entrada</MenuItem>
                <MenuItem value={"outgoing"}>Salida</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="dialogButtons">
            <Button onClick={() => setOpenEditor(false)} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
