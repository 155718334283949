import React, { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    TextField,
    Link,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import Box from 'elements/Box';
import { ProjectConfig } from 'Global';


const defaultValues = {
    email: '',
};

export default function FormForgotPass( props: any ) {
    
    const {
        loadingButton,
        setLoading,
        setState,
        setEmail,
    } = props;

    const [formValues, setFormValues] = useState(defaultValues);

    /*================================================
    SNACKBAR FUNCTION FOR NOTIFICATIONS
    ================================================*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */


    /*================================================
    FORGOT PASSWORD (SUBMIT)
    ================================================*/
    const handleSubmit = () => {
        setLoading(true);
        axios.get(ProjectConfig.api_url+"/auth/reset_pass", {
            params: {
              email: formValues.email,
            },
        })
        .then((response) => {
            if(response.data.status === "success"){
                enqueueSnackbar(response.data.message, { variant: 'success' });
                setState('credentialsSent');
            } else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
            }
        })
        .catch((error) => {
            enqueueSnackbar(error.toString(), { variant: 'error' });
        })
        .finally(() => {
            setLoading(false);
        });
        setState('credentialsSent'); // REMOVE THIS LINE
    };
    /*================================================
    ================================================*/

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
      const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: value,
      });
    };

    return(
        <Box id='loginBox'>

          

          <div className='loginFieldsContainer'>
            <h1 className='h1_login'>Recuperar contraseña</h1>

            <p>Hemos enviado un correo electronico con las instrucciones para el restablecimiento de la contraseña</p>
            
              
          </div>

          <div className='loginButtonContainer'>
            <LoadingButton
              size="small"
              type="submit"
              loading={loadingButton}
              fullWidth
              variant="contained"
              sx={{ fontSize: '13px', padding: '10px 0px'}}
              onClick={handleSubmit}
            >
              Continuar
            </LoadingButton>

            <Link
              component="button"
              variant="subtitle2"
              underline="none"
              onClick={() => {
                  setEmail('');
                  setState('login')
              }}
              sx={{display:'flex', alignItems:'center', fontSize: 'small', justifyContent: 'start', columnGap: '5px', padding: '0px 0px 10px 0px'}}
            >
              <ArrowBack sx={{ fontSize: 'small' }} />
              Volver
            </Link>
          </div>
        </Box>
    );
}