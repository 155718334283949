import axios from "axios";
import { useEffect, useState } from "react";
import Box from "elements/Box";
import { Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ProjectConfig } from "Global";
import AlertDialog from "elements/AlertDialog";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useSnackbar } from "notistack";

import "assets/css/main.css";

import AssetsConceptEditor from "./AssetsConceptEditor";

export default function AssetsConceptList(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const currentPage = parseInt(localStorage.getItem("assetConceptPage") || "1");
  const [page, setPage] = useState(currentPage);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page]);

  const loadList = () => {
    setLoading(true);

    axios
      .get(ProjectConfig.api_url + "/assets/list_concepts", {
        headers: {
          ltkn: localStorage.getItem("ltkn"),
        },
        params: {
          page: page,
        },
      })
      .then((response) => {
        setList(response.data.list);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });

    localStorage.setItem("assetConceptPage", page.toString());
  };

  const handleDelete = (asset_concept_id: number) => {
    setActiveItem(asset_concept_id);
    setOpenAlert(true);
  };

  const deleteItem = () => {
    const body = JSON.stringify({
      asset_concept_id: activeItem,
    });

    axios
      .post(ProjectConfig.api_url + "/assets/deleteItem_concepts", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          enqueueSnackbar("Concepto de activo eliminado con éxito", { variant: "success" });
          loadList();
        }
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setOpenAlert(false);
      });
  };

  return (
    <div id="AssetConceptList" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Conceptos de activos</h2>
            <span className="h2_subtitle">Lista de conceptos de activos</span>
          </div>
          <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveItem(null);
                setOpenEditor(true);
              }}
              startIcon={<AddIcon />}
            >
              Crear concepto de activo
            </Button>
          </div>
        </div>

        <Box id="AssetConceptListIn" className="box100">
          <TableContainer>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>ID</TableCell>
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>ID DE LA COMPAÑÍA</TableCell>
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}>CONCEPTO</TableCell>
                  <TableCell sx={{ color: "#a8a8a8", borderBottom: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item, i) => (
                  <TableRow key={i} hover role="checkbox">
                    <TableCell>{item.asset_concept_id}</TableCell>
                    <TableCell>{item.company_id}</TableCell>
                    <TableCell>{item.concept}</TableCell>
                    <TableCell align="right">
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px" }}
                        startIcon={<ModeEditIcon />}
                        onClick={() => {
                          setActiveItem(item.asset_concept_id);
                          setOpenEditor(true);
                        }}
                      >
                        Editar
                      </Button>
                      <IconButton aria-label="delete" color="primary" onClick={() => handleDelete(item.asset_concept_id)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
            style={{ marginTop: "20px", alignSelf: "center" }}
          />
        </Box>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="¿Está seguro que desea eliminar este concepto de activo?"
        send={deleteItem}
      />

      <AssetsConceptEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        asset_concept_id={activeItem}
        loadList={loadList}
      />
    </div>
  );
}
