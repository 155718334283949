import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import ic_menu from 'icons/ic_menu.svg';
import LogoCasoBox from 'icons/LogoCasoBox.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import Cookies from 'universal-cookie';
import AppAvatar from 'elements/AppAvatar';
import SpeedIcon from '@mui/icons-material/Speed';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PersonIcon from '@mui/icons-material/Person';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StarIcon from '@mui/icons-material/Star';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { Permissions } from 'Permissions';

import {
  AdminPanelSettings,
  Business,
  Category,
  Inventory2,
  RocketLaunch,
  SupervisorAccount
} from '@mui/icons-material';
import MenuButton from 'elements/MenuButton';



import 'assets/css/main.css';

const cookies = new Cookies();

const styleMenu = {
  buttons: {
    textDecoration: 'none',
    padding: '10px 20px',
    width: '100%',
    justifyContent: 'start',
    color: '#637381',
  },





  
  button_labels: {
    "&:hover": {
      backgroundColor: 'transparent',
    },
    textTransform: 'uppercase',
  },
  button_imgs: {
  },
  accordion: {
    padding: '0px 20px',
    bgcolor: 'transparent',
    color: '#ffffff',
    boxShadow: 'none',
    '&:hover': {backgroundColor: 'transparent'},
  },
  accordion_buttons: {
    display: 'flex',
    color: '#222',
    fontSize: '14px',
    width: '100%',
    justifyContent: 'start',
    columnGap: '10px',
    alignItems: 'center',
  },
  accordion_img_company: {
    width: '30px',
    height: '30px',
    margin: '0',
  },
  accordion_imgs: {
    width: '30px',
    height: '30px',
    padding: '4px',
    margin: '0',
  },
}

export default function MainMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const role_id = Number(localStorage.getItem('role_id'));

  return (
    <div className='menu_button_container'>

      {Permissions.map((permission, index) => {
        if(permission.role_id === role_id){
          return permission.routes.map((route, index) => {
            return (            
                <div key={index}>
                
                  <h2 className='menu_title'>{route.category}</h2>  
                  {route.components.map((item, index) => {
                    return <MenuButton key={index} label={item.label} to={item.path} startIcon={item.icon} /> 
                  })}
                </div>
            )
          })
        }
      })}


      

      


      
    </div>
  );
}
