import { PersonPin } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function MenuButton(props: any) {

  const { label, to, startIcon, onClickCallback, company_id = null, disabled = false } = props;

  const checkActiveMenu = (href: string) => {
    if (window.location.pathname.startsWith(href)) {
      return true;
    }else{
      return false;
    }
  }

  const styleMenu = {
    buttons: {
      textDecoration: 'none',
      padding: '10px 20px',
      width: '100%',
      justifyContent: 'start',
      color: '#637381',
      textTransform: 'none',
      //svg
      '& svg': {
        width: '25px',
        height: '25px',
        marginRight: '10px',
        color: checkActiveMenu(to)? '#ffffff' : '#1D3B47',
      },
    }
  }
  


  return (
    <Button
      disabled={disabled}
      component={Link}
      startIcon={startIcon}
        to={to}
      sx={styleMenu.buttons}
      onClick={onClickCallback}
      style={{
        color: checkActiveMenu(to)? '#ffffff' : '#637381',
        backgroundColor: checkActiveMenu(to)? '#1D3B47' : 'transparent',
      }}
      
      
      >
      {label}
    </Button>
  );
}