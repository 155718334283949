import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, AvatarGroup, Button, Chip, CircularProgress, Dialog, IconButton, Modal, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';

import 'assets/css/main.css'; 

import PlanEditor from './PlanEditor';

export default function PlanList(props: any) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, [])
  
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const [openEditor, setOpenEditor] = useState(false);



  useEffect(() => {
    setLoading(true);
    loadList();
    
  }, [page])

  /*=========================================================
  LOAD LIST
  =========================================================*/
  const loadList = () => {
    setLoading(true);

    axios.get(ProjectConfig.api_url+"/plans/list",
    {
      params: {
        page: page,
        role_id: 1,
      }
    })
    .then((response) => {
        console.log("Plan List ",response.data); 
        setList(response.data.list)
        setTotalPages(response.data.total_pages)
        setLoading(false)
    })
    .catch((err) => {
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false)
    })
    
    localStorage.setItem('page', page.toString())
}


  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (plan_id:number) => {
    setActiveItem(plan_id)
    setOpenAlert(true)
  }



  const deleteItem = () => {

    const body = {
      plan_id: activeItem
    }

    axios.post(ProjectConfig.api_url+"/users/delete", body)
      .then((response) => {
        console.log(response.data);
        if(response.data.status === "success"){
          enqueueSnackbar('Usuario eliminado con éxito', { variant: 'success' });
          loadList();
        }
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }
  

  /*=========================================================*/

// call global snackbar
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);

  
  return (
      <div id='UsersList' className='ModuleContainer'>
        <div className="ModuleWrapper"> 
          <div className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Planes</h2>
              <span className="h2_subtitle">Lista de planes creados</span>
            </div>
            
            <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveItem(null)
                setOpenEditor(true)
              }}
              startIcon={<AddIcon />}
            >
              Crear Plan
            </Button>

            </div>
            
          </div>

          <div id='UsersListIn'>
            
              <Box id='PlanList' className='box100'>
              <TableContainer>
              <Table aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>PLAN / ID</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>DISPOSITIVOS</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>MÓDULOS</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((item,i) => (
                      <TableRow 
                        
                        key={i}
                        hover
                        role="checkbox"
                      >
                        <TableCell>
                          <span className='flex_title'>{item.plan_name}</span>
                          <span className='flex_subtitle'>{item.email}</span>
                        </TableCell>

                      
                        <TableCell>
                          {item.devices_quantity}
                        </TableCell>

                        <TableCell>
                        <AvatarGroup sx={{justifyContent: 'left'}}>
                          {item.modules.reverse().map((module:any, i:number) => (
                            <Avatar key={i} sx={{ bgcolor: module.hexColor }}> {module.letter} </Avatar>
                          ))}
                        </AvatarGroup>
                        </TableCell>

                        <TableCell align="right">

                          <Button 
                            // component={Link}
                            // to={`edit/${user.plan_id}`}
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{"marginRight": "10px"}}
                            startIcon={<ModeEditIcon />}
                            onClick={() => {
                              setActiveItem(item.plan_id)
                              setOpenEditor(true)
                            }}
                          >
                            Editar
                          </Button>



                          <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(item.plan_id)}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                      </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
            </Box>
  
          </div>
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            subtitle="¿Está seguro que desea eliminar este usuario?"
            send={deleteItem}
        />



        <PlanEditor
          setLoading={setLoading}
          openEditor={openEditor} 
          setOpenEditor={setOpenEditor}
          plan_id={activeItem} 
          loadList={loadList}
        />



    </div>
  );
};