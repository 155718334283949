import * as React from 'react';
import {Link, Avatar, Button, Container, TextField, Typography, Dialog, DialogContent, InputAdornment, IconButton} from '@mui/material';
import logo from '../../assets/img/logo.svg';
import Cookies from 'universal-cookie';
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { resolve } from 'path';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SignUp from './SignUp';
import { useSearchParams } from 'react-router-dom';
import FormLogin from './FormLogin';
import FormCredentialsSent from './FormCredentialsSent';
import FormChangePass from './FormChangePass';
import FormForgotPass from './FormForgotPass';
const cookies = new Cookies();

export default withSnackbar(
  function SignIn(props: any) {
    
    const { setLoading } = props;
    
    useEffect (() => {
      setLoading(false);
    }, []);

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    const [loadingButton, setLoadingButton] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [state, setState] = useState('login');
    const [userEmail, setUserEmail] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [worstationCode, setWorstationCode] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();



  
    
  //IF TOKEN MOBILE APP EXISTS IN URL 
  const tokenApp = localStorage.getItem('tokenApp')? localStorage.getItem('tokenApp') : '';

  const invitation = localStorage.getItem('invitation')? localStorage.getItem('invitation') : '';

  useEffect (() => {
    const emailVar = localStorage.getItem('email')? localStorage.getItem('email') : '';
    setLoading(false);
    setEmail(emailVar || searchParams.get('email') || '');
    setCode(searchParams.get('code') || '');
    setWorstationCode(searchParams.get('workstation_code') || localStorage.getItem('workstation_code') ||'')
  // const workstation_code = localStorage.getItem('workstation_code')? localStorage.getItem('workstation_code') : '';
  }, []);

  useEffect (() => {
    if(invitation === "1" && email !== '' && worstationCode !== ''){
      setOpenRegister(true);
    }
  }, [worstationCode]);

  useEffect (() => {
    if (email && code) {
      setState('changePass');
    }
  }, [code]);

  const loginDataDefault = {
    email: email,
    password: '',
    remember_me: 1,
  }

  const [loginData, setLoginData] = useState(loginDataDefault);


  /*==========================================
  SIGN IN 
  ==========================================*/



  const sign_in = () => {

    const body = JSON.stringify({
      user_email: loginData.email,
      user_password: loginData.password,
      remember_me: 1,
    } );


      axios
        .post(ProjectConfig.api_url+"/auth/sign_in", {
          data: body,
        })
        .then((response) => {
          console.log(response.data);
          if(response.data.status === "success"){
            
            enqueueSnackbar(response.data.message, { variant: 'success' });
            const user_info = response.data.user_info;
            console.log(user_info);

            localStorage.setItem('ltkn', user_info.ltkn);
            localStorage.setItem('user_id', user_info.user_id);
            localStorage.setItem('user_name', user_info.user_name);
            localStorage.setItem('role_id', user_info.role_id);
            window.location.reload();
          }
          else
          {
            enqueueSnackbar(response.data.message, { variant: 'error' });
            setLoadingButton(false);
          }
          
         
        })
        .catch((err) => {
          enqueueSnackbar(err.message.toString(), { variant: 'error' });
          setLoadingButton(false);
        });

  }  

  return (
    <>
    <div className='loginContainer'>
      <div className='landingContainer'>
        <div className='landingLogoContainer'>
          <img src={logo} alt='logo' />
          <h2>Gestión Inteligente de negocios</h2>
        </div>
      </div>

    <div className='loginFormContainer'>
      {state === 'login' && (
        <FormLogin
          setLoading={setLoading}
          setState={setState}
          loginData={loginData}
          setEmail={setEmail}
          setLoginData={setLoginData}
          loadingButton={loadingButton}
          setLoadingButton={setLoadingButton}
          setOpenRegister={setOpenRegister}
          sign_in={sign_in}
        />
      )}
      {state === 'changePass' && (
        <FormChangePass
          loadingButton={loadingButton}
          setLoading={setLoading}
          setState={setState}
          email={email}
          code={code}
        />
      )}
      {state === 'forgotPass' && (
        <FormForgotPass
          loadingButton={loadingButton}
          setLoading={setLoading}
          setState={setState}
          email={email}
          setEmail={setEmail}
        />
      )}
      {state === 'credentialsSent' && (
        <FormCredentialsSent
          setLoading={setLoading}
          setState={setState}
          setEmail={setEmail}
          setLoginData={setLoginData}
          loginDataDefault={loginDataDefault}
        />
      )}
      {state === 'register' && (
        <SignUp
          setState={setState}
          loginData={loginData}
          setLoginData={setLoginData}
          sign_in={sign_in}
          setLoading = {setLoading}
        />
      )}

      </div>
    </div>

    </>
  );
}
);