import axios from "axios";
import { useEffect, useState } from "react";
import Box from "elements/Box";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ProjectConfig } from "Global";
import AlertDialog from "elements/AlertDialog";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useSnackbar } from "notistack";

import "assets/css/main.css";
import { useNavigate, useParams } from "react-router-dom";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AutocompleteStandard from "elements/AutocompleteStandard";
import AutocompleteReportUsers from "elements/AutocompleteReportUsers";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import ReportViewer from "./ReportViewer";

export default function ReportsForm(props: any) {
  const { setLoading } = props;

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

  useEffect(() => {
    setLoading(false);
  }, []);

  const defaultValues: any = {
    date: null,
    user_id: null,
  };

  

  

  const [exportType, setExportType] = useState("");

  const [formValues, setFormValues] = useState({...defaultValues});
  

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
    SUBMIT
  =======================================*/
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [data, setData] = useState([]);
  const [openViewer, setOpenViewer] = useState(false);

  const handleSubmit = () => {
    console.log(formValues);

    //AXIOS
    axios
      .get(ProjectConfig.api_url + `/reports/${exportType}`, {
        headers: {
          "ltkn": localStorage.getItem("ltkn")
        },
        params: {
          ...formValues,
        },
      })
      .then((response) => {
        console.log(response.data);
        if(response.data.status === "success"){
          setFileUrl(response.data.fileUrl);
          setData(response.data.data);
          //window.open(response.data.fileUrl, '_blank');
          setOpenViewer(true);
        }
        else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /*====================================
  =====================================*/


  return (
    <>
      <div id="SupplierList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Reportes</h2>
              <span className="h2_subtitle">Selecciona el tipo de exportación</span>
            </div>
            <div className="boxContainerActions"></div>
          </div>

          <Box id="SupplierListIn" className="box100">

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Reporte</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={exportType}
                label="Reporte"
                name="export_type"
                onChange={(e: any) => {
                  setExportType(e.target.value);
                  setFormValues({...defaultValues});
                    
                }}
              >
                <MenuItem value={"GetCountUnitInventory"}>Conteo de inventario unidades</MenuItem>
                <MenuItem value={"GetCountUsersUnit"}>Conteo de inventario unidades x usuario</MenuItem>
                <MenuItem value={"GetCountUsersDifferences"}>Diferencias</MenuItem>
                <MenuItem value={"GetPhysicalCountVersusInventory"}>Inventario físico vs sistema</MenuItem>
                <MenuItem value={"GetMissingAndLeftoverCount"}>Inventario faltante vs sobrante</MenuItem>
                <MenuItem value={"GetPrice"}>Inventario faltante vs sobrante x valores</MenuItem>
                <MenuItem value={"GetStock"}>Inventario físico vs stock</MenuItem>
              </Select>
            </FormControl>

            {exportType && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                  
                  label="Fecha"
                  inputFormat="MMMM DD, yyyy"
                  value={formValues.date}
                  InputProps={{
                      endAdornment: (
                      <EventIcon />
                      ),
                  }}
                  showToolbar={false}
                  closeOnSelect 
                  onChange={(newValue:any) => {
                      const val = newValue? (newValue).format('YYYY-MM-DD') : '';
                      setFormValues({
                          ...formValues,
                          date: val,
                      });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  />
              </LocalizationProvider>
            )}

            {formValues.date && (exportType === "GetCountUsersUnit" || exportType === "GetPhysicalCountVersusInventory" || exportType === "GetMissingAndLeftoverCount" || exportType === "GetPrice" || exportType === "GetStock") && (
            <AutocompleteReportUsers
              value={formValues.user_id}
              date={formValues.date}
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  user_id: e.target.value,
                });
              }}
            />
            )}



            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit} 
              startIcon={<CloudDownloadOutlinedIcon />}
            >
              Ver
            </Button>

          </Box>
        </div>
      </div>

      <ReportViewer
        open = {openViewer}
        setOpen = {setOpenViewer}
        data = {data}
        fileUrl = {fileUrl}
        setLoading = {setLoading}
      />
    </>
    
  );
}
