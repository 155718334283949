import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ProjectConfig } from "Global";
import Box from "elements/Box";
import Cookies from "universal-cookie";
import { Button } from "@mui/material";

const cookies = new Cookies();

export default function Home(props: any) {
  const { setLoading } = props;

  return (
    <div
      id="Dashboard"
      className="ModuleContainer"
    >
      <div className="ModuleWrapper">
        <div id="userWorkstationsHeader" className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Dashboard</h2>
            <span className="h2_subtitle">Test</span>
          </div>

          <div className="boxContainerActions">
            <Button variant="contained" color="primary">
              Test
            </Button>
          </div>
        </div>

        <div id="DashboardIn">
          <Box>
            Content
          </Box>
        </div>
      </div>
      
    </div>
  );
}
