import { Button, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

export default function ReportViewer(props: any) {
  const { setLoading, open, setOpen, fileUrl, data } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = () => {
    window.open(fileUrl, '_blank');
  }

  return (
    <>
      <Dialog 
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth={true}
        maxWidth="lg"

      >
        <div className="DialogContainer" style={{ width: 'auto' }}>
          <div className="ModuleWrapper">
            <div className="boxContainerHeader">
              <div className="boxContainerTitle">
                <h2 className="h2_title">Reporte de Inventario</h2>
                <span className="h2_subtitle">Detalle</span>
              </div>
            </div>

            <div className="content">
              {data && data.headers && data.rows && data.rows.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {data.headers.map((header: string) => (
                          <TableCell key={header}>{header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.rows.map((row: any, index: number) => (
                        <TableRow key={index}>
                          {data.headers.map((header: string) => (
                            <TableCell key={header}>{row[header]}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="h6" align="center" style={{ padding: '20px' }}>
                  No hay datos disponibles para mostrar.
                </Typography>
              )}
            </div>
          </div>
        </div>

        <div className="dialogButtons" style={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            className="button_2"
          >
            Cerrar
          </Button>

          <Button onClick={handleDownload} variant="contained" className="button_1">Descargar</Button>
        </div>
      </Dialog>
    </>
  );
}
