import * as React from 'react';
import {Link, Avatar, Button, Container, TextField, Typography, Dialog, DialogContent, InputAdornment, IconButton, SwitchProps, Switch, styled, FormControlLabel} from '@mui/material';
import logo from '../../assets/img/logo.png';
import Cookies from 'universal-cookie';
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { resolve } from 'path';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ISwitch from 'elements/ISwitch';
const cookies = new Cookies();


// {
//   "user_email": "prueba@mail.com",
//   "user_name": "Prueba",
//   "user_last_name": "Prueba",
//   "user_password": "123456789",
//   "user_password_confirmation": "123456789",
//   "user_phone": "0000000000"
// }


export default withSnackbar(
  function SignUp(props: any) {

    

    const defaultValues: any = {
      user_email: '',
      user_name: '',
      user_last_name: '',
      user_password: '',
      user_password_confirmation: '',
      user_phone: '',
      user_phone_code: '',
    }

    const { setState, setLoading, sign_in, loginData, setLoginData} = props;

    
  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */


  const [formValues, setFormValues] = useState(defaultValues);
  const [showPassword, setShowPassword] = useState(false);




  const handleInputChange = (e: { target: { name: any; value: any; required: any}; }) => {

    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
    
    
  };



  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  const checkEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  
    

  /*================================================
  SIGN UP (SUBMIT)
  ================================================*/
  async function handleRegister(){

    if(formValues.name === '' || formValues.lastname === '' || formValues.email === '' || formValues.password === '' || formValues.password_confirmation === ''){
      //snackbar error
      enqueueSnackbar('Todos los campos son obligatorios', { variant: 'error' });
      return;
    }
      
    if(formValues.password !== '' && formValues.password !== formValues.password_confirmation){
      //snackbar error
      enqueueSnackbar('Las contraseñas no coinciden', { variant: 'error' });
      return;
    }

    if(!checkEmail(formValues.email)){
      //snackbar error
      enqueueSnackbar('El correo electrónico no es válido', { variant: 'error' });
      return;
    }

    if(formValues.is_company && formValues.company_name === ''){
      //snackbar error
      enqueueSnackbar('El nombre de la empresa es obligatorio', { variant: 'error' });
      return;
    }

    if(formValues.is_company && formValues.company_document === ''){
      //snackbar error
      enqueueSnackbar('El número de documento de la empresa es obligatorio', { variant: 'error' });
      return;
    }
    
    
  

    setLoading(true);

    axios.post(ProjectConfig.api_url, null,
      {
        params: {
          request: 'sign_up',
          formdata: formValues,
        }
      })
        .then((response) => {
          console.log(response.data);

          if(response.data.status === "success"){
            enqueueSnackbar(response.data.message, { variant: 'success' });
            loginData.email = formValues.email;
            loginData.password = formValues.password;
            setLoginData(loginData);

            sign_in(formValues.email, formValues.password);
          }
          else
          {
            enqueueSnackbar(response.data.message, { variant: 'error' });
            setLoading(false);
          }

           
        })
        .catch((err) => {
          console.log(err.data);
        })

    


    return
  }

  //IF IS USER IS CHECKED, IS COMPANY IS UNCHECKED AND VICEVERSA
  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.name === 'is_user'){
      setFormValues({
        ...formValues,
        is_user: event.target.checked,
        is_company: !event.target.checked,
      });
    }
    else{
      setFormValues({
        ...formValues,
        is_user: !event.target.checked,
        is_company: event.target.checked,
      });
    }

  };


  




  return (
    <>
      <Box id='registerBox'>
        
        <div className='loginFieldsContainer'>
            <h1 className='h1_login'>Iniciar Sesión</h1>
              <form className='form' id='registerForm'>

                <div className='RegisterSteps'>
                  <div className='RegisterStep' id='step1'>
                    <div className='RegisterStepCircle'>1</div>
                    <div className='RegisterStepText'>Usuario</div>
                  </div>
                  <div className='RegisterStep' id='step2'>
                    <div className='RegisterStepCircle'>2</div>
                    <div className='RegisterStepText'>Empresa</div>
                  </div>
                  <div className='RegisterStep' id='step3'>
                    <div className='RegisterStepCircle'>3</div>
                    <div className='RegisterStepText'>Acceso</div>
                  </div>
                </div>

            <div className='login_input_row'>
              <TextField
                required
                fullWidth
                id="user_email"
                label="Correo electrónico"
                name="user_email"
                type="text"
                value={formValues.user_email}
                autoComplete="user_email"
                onChange={handleInputChange}
              />

              <div className='input_row'>
                <TextField
                  required
                  id="user_phone_code"
                  label="Cod"

                  style={{width: '100px'}}
                  name="user_phone_code"
                  type="text"
                  autoComplete="phone_code"
                  onChange={handleInputChange}
                  value={formValues.user_phone_code}
                />
                <TextField
                  required
                  fullWidth
                  id="user_phone"
                  label="Teléfono"
                  name="user_phone"
                  type="text"
                  autoComplete="phone"
                  onChange={handleInputChange}
                  value={formValues.user_phone}
                />
              </div>

            </div>
        
          
            <div className='login_input_row'>
              

              <TextField
                required
                fullWidth
                id="user_name"
                label="Nombre"
                name="user_name"
                type="text"
                autoComplete="name"
                onChange={handleInputChange}
                value={formValues.user_name}
              />

              <TextField
                required
                fullWidth
                id="user_last_name"
                label="Apellido"
                name="user_last_name"
                type="text"
                autoComplete="lastname"
                onChange={handleInputChange}
                value={formValues.user_last_name}
              />

              
            </div>

            
              

            <div className='login_input_row'>
              <TextField
                required
                fullWidth
                name="user_password"
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                id="user_password"
                value={formValues.user_password}
                autoComplete="current-password"
                onChange={handleInputChange}
                // eye icon to show password
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                
              />

              

              <TextField
                required
                fullWidth
                name="user_password_confirmation"
                label="Confirma contraseña"
                type={showPassword ? 'text' : 'password'}
                id="user_password_confirmation"
                value={formValues.user_password_confirmation}
                onChange={handleInputChange}
                error={formValues.user_password_confirmation !="" && formValues.user_password !== formValues.user_password_confirmation}
                helperText={formValues.user_password_confirmation !="" && formValues.user_password !== formValues.user_password_confirmation ? 'Las contraseñas no coinciden' : ''}
                // eye icon to show password
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            </form>
        </div>

        <div className='loginButtonContainer'>


            <Button
              fullWidth
              variant="contained"
              onClick={handleRegister}
            >
              Registrarme
            </Button>

            <Button
              fullWidth
              variant="outlined"
              onClick={()=>{
                setState('login');
              }}
            >
              Ya tengo cuenta
            </Button>

        </div>
      </Box>
            
    </>
  );
}
);