import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from "Global";

export default function AssetsConceptEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, asset_concept_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    asset_concept_id: asset_concept_id ? asset_concept_id : "null",
    concept: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (asset_concept_id) {
      axios
        .get(`${ProjectConfig.api_url}/assets/details_concepts`, {
          params: { asset_concept_id },
        })
        .then((response) => {
          setFormValues(response.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      setFormValues(defaultValues);
    }
  }, [asset_concept_id]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);

    axios
      .post(ProjectConfig.api_url + "/assets/addedit_concepts", body, {
        headers: {
          "ltkn": localStorage.getItem("ltkn"),
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.status === "success") {
          enqueueSnackbar("Concepto de activo guardado con éxito", { variant: "success" });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)}>
      <div className="DialogContainer" style={{ maxWidth: "502px" }}>
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Concepto de activo</h2>
              <span className="h2_subtitle">Editar</span>
            </div>
          </div>

          <div className="input_group">
            <TextField
              id="concept"
              name="concept"
              label="Concepto"
              type="text"
              required
              fullWidth
              value={formValues.concept}
              onChange={handleInputChange}
            />
          </div>

          <div className="dialogButtons">
            <Button onClick={() => setOpenEditor(false)} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
