
import Dashboard from 'components/Dashboard/Dashboard';
import AdminList from 'components/Administrators/AdminList';
import {BrowserRouter as Router , Route, Routes, Navigate} from 'react-router-dom'
import Cookies from 'universal-cookie';
import CompanyList from 'components/Companies/CompanyList';
import PlanList from 'components/Plans/PlanList';
import Warehouses from 'components/Warehouses/WarehouseList';
import Products from 'components/Products/ProductsList';
import Categories from 'components/Categories/CategoriesList';
import Suppliers from 'components/Suppliers/SuppliersList';
import Brands from 'components/Brands/BrandsList';
import Users from 'components/Users/UsersList';
import Sync from 'components/Import/Sync';
import Reports from 'components/Reports/Reports';
import {Permissions} from './Permissions'; 
import Clients from 'components/Clients/ClientsList';
import MovementsConcepts from 'components/MovementsConcepts/ConceptsList';
import UndeliveredConcepts from 'components/UndeliveredConcepts/UndeliveredConceptList';
import AssetsConcepts from 'components/AssetsConcepts/AssetsConceptList'; 
import Guides from 'components/Guides/GuideList';
import Movements from 'components/Movements/MovementList';

const cookies = new Cookies();
 
const componentMap: any = {
  Dashboard,
  Warehouses,
  Products,
  Categories,
  Suppliers,
  Brands,
  Users,
  Sync,
  Reports,
  AdminList,
  CompanyList,
  PlanList,
  Clients,
  MovementsConcepts,
  UndeliveredConcepts,
  AssetsConcepts,
  Guides,
  Movements
}




export default function AppRoutes(props:any) {

  const role_id = Number(localStorage.getItem('role_id'));
  const {setLoading} = props;
  
  return (
    <>
      <Routes>

          {Permissions.map((permission, index) => {
            if(permission.role_id === role_id){
              return permission.routes.map((route, index) => {
                return route.components.map((item, index) => {
                  const Component = componentMap[item.component];
                  return <Route path={item.path} element={<Component setLoading={setLoading}/>} />
                })
              })
            }
          })}





      </Routes>
    </>

  );
}
