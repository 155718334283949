import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";



export default function WarehouseEditor(props : any) {

  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    warehouse_id,
    loadList
  } = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */



  const defaultValues: any = {
    warehouse_id: warehouse_id? warehouse_id : "null",
    warehouse_name: "",
    address: "",
    city: "",
    warehouse_code: "",
  };
  
  const [formValues, setFormValues] = useState(defaultValues)


  /*=======================================
  GET USER DETAILS IF ID EXISTS
  =======================================*/
  useEffect(() => {
    if (warehouse_id) {
      axios.get(ProjectConfig.api_url + "/warehouses/details", {
          params: {
            warehouse_id: warehouse_id,
          },
        })
        .then((response) => {
          console.log("Warehouse details", response.data)
          setFormValues(response.data.data);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setFormValues(defaultValues);
    }
    
  } , [warehouse_id])




  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);
    
    axios.post(ProjectConfig.api_url+"/warehouses/addedit", body,
      {
        headers: {
          "ltkn": localStorage.getItem("ltkn"),
        },
      })
      .then((response) => {
        console.log(response.data);

        if(response.data.status === "success"){
          enqueueSnackbar('Bodega guardada con éxito', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.response.data);
      }).
      finally(() => {
        setLoading(false);
      });  
  };






  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="DialogContainer" style={{maxWidth:'502px'}}>
              <div className="ModuleWrapper">
                  <div className="boxContainerHeader">
                      <div className="boxContainerTitle">
                          <h2 className="h2_title">Bodega</h2>
                          <span className="h2_subtitle">Editar</span>
                      </div>

                      
                  </div>

                  
                 
                  <div className="input_group">
                    <div className='input_row'>

                      <TextField
                        id="warehouse_name"
                        name="warehouse_name"
                        label="Nombre de la Bodega"
                        type="text"
                        required
                        fullWidth
                        value={formValues.warehouse_name}
                        onChange={handleInputChange}
                      />
                  

                      <TextField
                        id="warehouse_code"
                        name="warehouse_code"
                        label="Código"
                        type="text"
                        required
                        value={formValues.warehouse_code}
                        onChange={handleInputChange}
                        sx={{maxWidth: '200px'}}
                      />
                    </div>

                    <TextField
                      id="city"
                      name="city"
                      label="Ciudad"
                      type="text"
                      required
                      fullWidth
                      value={formValues.city}
                      onChange={handleInputChange}
                    />

                    <TextField
                      id="address"
                      name="address"
                      label="Dirección"
                      type="text"
                      required
                      fullWidth
                      value={formValues.address}
                      onChange={handleInputChange}
                      
                    />
                    

                  </div>
                  
     

                </div>
              
              </div>
         

          <div className="dialogButtons">
            <Button
                onClick={() => setOpenEditor(false)}
                variant="outlined"
                className="button_2"
            >
              Cancelar
            </Button>

            <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
          </div>

        </Dialog>
      </>
  );
}
